import { sampleSize, shuffle } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { filterClassName } from '../utilities/ClassNameTools';

export enum CardContentType {
  Text = 'text'
}

export interface CardContent {
  wordId: number
  type: CardContentType
  value: string
}

export interface CardContentWithState extends CardContent {
  hidden: boolean
}

const dict: CardContent[][] = [["あ", "ア", "a"], ["い", "イ", "i"], ["う", "ウ", "u"], ["え", "エ", "e"], ["お", "オ", "o"], ["か", "カ", "ka"], ["き", "キ", "ki"], ["く", "ク", "ku"], ["け", "ケ", "ke"], ["こ", "コ", "ko"], ["さ", "サ", "sa"], ["し", "シ", "si"], ["す", "ス", "su"], ["せ", "セ", "se"], ["そ", "ソ", "so"], ["た", "タ", "ta"], ["ち", "チ", "chi"], ["つ", "ツ", "tsu"], ["て", "テ", "te"], ["と", "ト", "to"], ["な", "ナ", "na"], ["に", "ニ", "ni"], ["ぬ", "ヌ", "nu"], ["ね", "ネ", "ne"], ["の", "ノ", "no"], ["は", "ハ", "ha"], ["ひ", "ヒ", "hi"], ["ふ", "フ", "fu"], ["へ", "ヘ", "he"], ["ほ", "ホ", "ho"], ["ま", "マ", "ma"], ["み", "ミ", "mi"], ["む", "ム", "mu"], ["め", "メ", "me"], ["も", "モ", "mo"], ["や", "ヤ", "ya"], ["ゆ", "ユ", "yu"], ["よ", "ヨ", "yo"], ["ら", "ラ", "ra"], ["り", "リ", "ri"], ["る", "ル", "ru"], ["れ", "レ", "re"], ["ろ", "ロ", "ro"], ["わ", "ワ", "wa"], ["を", "ヲ", "wo"], ["ん", "ン", "n"]].map((w, i) => {
  return w.map((t) => ({
    wordId: i,
    type: CardContentType.Text,
    value: t
  }))
})

function App() {
  const [boardSize, setBoardSize] = useState(4)
  const [board, setBoard] = useState<CardContentWithState[][]>([])
  const [selectPosition, setSelectPosition] = useState<[number, number] | null>(null)
  const [wordRemains, setWordRemains] = useState(0)
  const [correctCount, setCorrectCount] = useState(0)
  const [wrongCount, setWrongCount] = useState(0)
  const [round, setRound] = useState(0)

  const createBoard = useCallback(() => {
    const len = boardSize * boardSize
    setWordRemains(len / 2)
    const cards = shuffle(sampleSize(dict, len / 2).map((i) => sampleSize(i, 2)).flat())

    let i = 0
    const board = new Array(boardSize).fill(undefined).map(() => new Array(boardSize).fill(undefined).map(() => ({ ...cards[i++], hidden: false })))

    setBoard(board)
  }, [boardSize])

  const handleCardClick = useCallback((y: number, x: number) => {
    const selectCard = board[y][x]

    if (!selectPosition) return setSelectPosition([y, x])
    if (selectPosition[0] === y && selectPosition[1] === x) return setSelectPosition(null)

    const currentCard = board[selectPosition[0]][selectPosition[1]]
    if (currentCard.wordId === selectCard.wordId) {
      setBoard((board) => {
        board[selectPosition[0]][selectPosition[1]] = { ...board[selectPosition[0]][selectPosition[1]], hidden: true }
        board[y][x] = { ...board[y][x], hidden: true }
        return board
      })
      setWordRemains((i) => i - 1)
      setCorrectCount((i) => i + 1)
    } else {
      setWrongCount((i) => i + 1)
    }

    setSelectPosition(null)
  }, [board, selectPosition])

  useEffect(() => {
    if (!wordRemains) {
      setRound((i) => i + 1)
      createBoard()
    }
  }, [createBoard, wordRemains])

  return (
    <div className="App p-4 max-w-xl mx-auto">
      <div className="flex p-4 items-center">
        <div className="font-black text-gray-700 text-4xl flex-1">Relations</div>
        <div className="bg-green-200 text-green-600 flex items-center justify-center px-4 leading-normal rounded ml-2 text-2xl font-black">{correctCount}</div>
        <div className="bg-red-200 text-red-600 flex items-center justify-center px-4 leading-normal rounded ml-2 text-2xl font-black">{wrongCount}</div>
      </div>
      <div className="gameboard">{
        board.map((line, y) => {
          return (
            <div key={`line-${y}`} className="flex my-4 px-2">{
              line.map((item, x) => {
                const referenceKey = `item-${y}-${x}`
                return <div
                  key={referenceKey}
                  className={filterClassName(
                    "flex-1 mx-2 p-4 rounded flex items-center justify-center cursor-pointer break-all hover:bg-gray-400",
                    (selectPosition && selectPosition[0] === y && selectPosition[1] === x) ? " bg-blue-300" : " bg-gray-300",
                    item.hidden && 'invisible'
                  )}
                  onClick={() => handleCardClick(y, x)}
                >
                  {item.value}
                </div>
              })
            }</div>
          )
        })
      }</div>
      <div className="text-center text-xs text-gray-600 mt-6">Round {round}, AccuracyRate {((correctCount / ((correctCount + wrongCount) || 1)) * 100).toFixed(2)}%</div>
    </div>
  );
}

export default App;